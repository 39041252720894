@import "../../styles/unitConverter.scss";
@import "../../styles/colors.scss";

:root {
  ---font-color: white;
  --padding-horizontal: 1.7rem;
}

.action-header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;
  padding: 0.8rem 3.7rem 0.8rem 2.7rem;
  position: relative;

  .title-div {
    display: flex;
    align-items: center;
    .title-heading {
      display: flex;
      align-items: center;
      .title {
        color: var(---font-color);
        font-weight: bold;
        font-size: 1.6rem;

        &[data-bg-color="light-dark"] {
          color: $primary;
        }
      }
      p {
        margin-left: 5px;
        color: white;
      }
    }
    > img {
      height: 35px;
      width: 35px;
      margin-right: 0.3em;
    }

    .sub-title {
      color: var(---font-color);
      font-weight: 400;
      margin-left: 0.6em;
      margin-top: 5px;
      font-size: 1.2rem;
    }
  }

  .buttons-group {
    display: flex;
    margin-left: 4rem;

    button:nth-child(1) {
      margin-right: 0.5em;
    }
  }

  .calendar {
    display: flex;
    justify-content: flex-end;

    .selected-date {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      .month {
        color: var(---font-color);
        font-weight: 700;
        font-size: 1.4rem;
        margin: 0px 5px 0px 0px;
      }

      .date {
        color: var(---font-color);
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }

  .arrows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    margin-top: 0.2em;
    cursor: pointer;

    > img {
      width: 0.6em;
      height: 0.6em;
    }
  }
}

@media only screen and (max-width: 1230px) {
  .action-header {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .buttons-group {
      margin-top: 1em;
      width: 50%;
      margin-left: 0rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .action-header {
    display: grid;
    grid-template-columns: 1fr;

    .input-div {
      margin-left: 0;
      margin-top: 0.5rem;
    }

    .buttons-group {
      margin-top: 1em;
      align-self: center;
      width: 13em;
      margin-left: 0rem;
    }

    .arrows {
      margin-top: 3px;
      > img {
        width: 8.5px;
        height: 8.5px;
      }
    }
  }
}
