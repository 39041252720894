.status-button {
  padding: 0.3rem 0.5rem;
  border-style: none;
  border-radius: 8px;
  align-self: center;
  width: max-content;

  p {
    font-size: 0.7rem;
    margin: 0px;
  }

  &[data-status="approved"] {
    background-color: #067306;

    p {
      color: white;
    }
  }

  &[data-status="active"] {
    background-color: #cbffae;

    p {
      color: #067306;
    }
  }

  &[data-status="pending"] {
    background-color: #005150;

    p {
      color: white;
    }
  }

  &[data-status="planning"] {
    background-color: #fffd5d;

    p {
      color: #c98800;
    }
  }

  &[data-status="syncing"] {
    background-color: #ffe5a9;

    p {
      color: #3f663f;
    }
  }

  &[data-status="offline"] {
    background-color: #ffa6eb;

    p {
      color: #3a3a3a;
    }
  }
}
