.button {
  padding: 0.15rem 0.6rem;
  border-style: none;
  border-radius: 8em;
  align-self: flex-start;
  width: max-content;

  p {
    margin: 0px;
    font-size: 0.8em;
  }

  &[data-status="out-of-order"] {
    background-color: #f3e6e5;

    p {
      color: #9b1f13;
    }
  }

  &[data-status="occupied"] {
    background-color: #f7eeca;

    p {
      color: #81690c;
    }
  }

  &[data-status="charging"] {
    background-color: #dfecfc;

    p {
      color: #004d99;
    }
  }

  &[data-status="available"] {
    background-color: #e7f3ed;

    p {
      color: #188639;
    }
  }

  &[data-status="offline"] {
    background-color: #ebebef;

    p {
      color: black;
    }
  }
}
