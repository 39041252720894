@import "../../styles/colors.scss";

.sidebar-mobile-overlay {
  display: flex;
  flex-direction: column;
  height: 91vh;
  align-items: flex-start;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0rem 0rem 1.5rem 0rem;
  background-color: #002726;
  .first-section {
    text-align: center;
    .logo {
      margin: 30px 0px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
  &.hidden {
    display: none;
  }

  &[data-is-sidebar-open="false"] {
    align-items: center;

    .top-section {
      padding: 0rem 1.45rem;
    }
  }

  .menu-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    .menu-item {
      display: flex;
      background-color: transparent;
      align-self: center;
      border-style: none;
      margin-bottom: 30px;
      padding: 0 0 0 36px;
      cursor: pointer;
      > img {
        width: 24px;
        height: 24px;
      }
      .active-menu {
        width: 39px;
        padding-left: 35px;
        opacity: 0;
        &[data-active="true"] {
          opacity: 1;
        }
      }
      &[data-disabled="true"] {
        cursor: default;
      }
    }
  }

  .logout-button {
    align-self: center;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
  }
}
.more-menu {
  padding: 20px 0 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: auto;

  .more-menu-top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .overview-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-arrow-div {
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #002726;
        border-radius: 5px;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }
      h2 {
        margin-left: 20px;
        text-align: center;
        color: white;
        font-size: 28px;
      }
    }
  }
  .inner-more-menu {
    margin-top: 10px;
    padding: 0 10px;
    h3 {
      color: white;
      font-size: 15px;
      line-height: 28px;
      cursor: pointer;
      margin: 10px 0px;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
    h3:hover {
      color: #7ac344;
    }
    &[data-disabled="true"] {
      cursor: default;
    }
  }
  .more-menu-bottom {
    .more-tools {
      background-color: transparent;
      align-self: center;
      border-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      cursor: pointer;
      margin: 0 10px;
      h3 {
        color: white;
        font-size: 12px;
        line-height: 28px;
        margin: 5px 0px;
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
      &:hover {
        color: #7ac344;
      }
      &[data-disabled="true"] {
        cursor: default;
      }
    }
    .top-section {
      margin-top: 20px;
    }
    h5 {
      color: white;
      margin: 10px 0px 0px 10px;
      cursor: pointer;
    }
  }

  h6 {
    color: white;
    font-size: 15px;
    font-weight: 400;
    margin-top: 36px;
  }
  .divider {
    background-color: white;
    height: 2px;
    margin: 16px 0px 40px 0;
  }
}

@media only screen and (max-width: 992px) {
  .sidebar-mobile-overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    background-color: rgba(22, 51, 51, 0.88);
    left: 0;
  }
  .more-menu {
    z-index: 999;
    background-color: #003131;
    width: max-content;
    position: absolute;
    height: 100%;
    padding: 20px 32px;
    left: 99px;

    &[data-is-sidebar-open="false"] {
      display: none;
    }
  }
}
