fieldset {
  border: 1.5px solid rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  border-radius: 4px;
  height: 3.5rem;
  margin-top: 1rem;
  padding: 0px 0px 0px 0px;
  width: 96%;

  &[data-is-disabled='true']{
    opacity: 0.3;
    pointer-events: none;
  }

  > legend {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #506176;
    margin-left: 10px;
  }

  .input-wrapper {
    background-color: transparent;
    border: 0px solid transparent;
    box-sizing: border-box;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0);
  }

  .dropdown-react {
    border: 0px solid rgba(0, 0, 0, 0);
    height: 100%;
    top: 0;
  }

  .Dropdown-control {
    border: 0px solid transparent;
    font-size: 0.8rem;
    height: 100%;
  }

  .Dropdown-menu {
    top: -9.8em
  }
}
