.map-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  .marker {
    background-image: url("markerIcon.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 59px;
    cursor: pointer;
  }
  .marker[type="1"] {
    background-image: url("markerIcon-green.png");
  }
  .marker[type="3"] {
    background-image: url("markerIcon-orange.png");
  }
  .map {
    flex: 1;
  }

  .card-on-map {
    position: absolute;
    width: 100%;
    top: 175px;
    pointer-events: none;

    .content {
      max-height: calc(100vh - 280px);
      pointer-events: all;
      overflow-y: auto;
    }

    .content.list-open {
      min-height: 348px;
    }

    .container {
      display: flex;

      .floating-card {
        width: 320px;
        pointer-events: all;
      }
    }
  }

  .map-image-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 10px;
    :nth-child(1) {
      object-fit: contain;
      height: 70px;
      width: 180px;
    }
    :nth-child(2) {
      object-fit: contain;
      height: 80px;
      width: 180px;
    }
  }
}
