@import "../../styles/colors.scss";


.main-content {
  display: flex;
  flex: 1;
  position: relative;

  .screen-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 85.22vh;
    position: relative;
    background-color: $dark_background;
    border-radius: 12px;
    overflow: hidden;

    >div{
      min-height: 85.22vh
    }

    margin-top: 14px;
    &[data-bg-color="light-dark"] {
      background-color: #eeeeee;
    }

    overflow-y: auto;

    // > :after {
    //   content: "";
    //   display: block;
    //   height: 15px;
    //   width: 100%;
    // }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #002726;
    }

    &::-webkit-scrollbar {
      border-radius: 10px;
      width: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ffffffbb;
    }

    .loader {
      width: 100%;
      background-color: #003b3b;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      color: white;
      font-size: 24px;
      font-weight: 700;
      padding: 24px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .screen-content {
    &[data-is-sidebar-open="true"] {
      opacity: 0.3;
      overflow-y: hidden;
    }
  }
}