.rdt_TableRow {
  padding: 0.4em 0em !important;
  &:hover {
    cursor: pointer;
    background-color: #005150 !important;
  }
}

.charging-stations-container {
  height: 100%;

  .top-bar-div {
    margin-left: 3rem;
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;

    .bar-div {
      margin-right: 1em;
      margin-top: 0.5em;
      > p {
        color: white;
        font-size: 0.75em;
        font-weight: 500;
        margin: 0 0.8em;
        cursor: pointer;
      }

      .bar-line {
        margin-top: 0.35em;
        height: 2px;

        &[data-line-color="All"] {
          background-color: #ebebeb;
        }
        &[data-line-color="Available"] {
          background-color: #7ac142;
        }
        &[data-line-color="Charging"] {
          background-color: #014f4f;
        }
        &[data-line-color="Requires Investigation"] {
          background-color: #fcb623;
        }
      }
    }
  }

  .rdt_TableCell {
    font-weight: 500;
    margin-left: 15px;
    padding: 0.5rem 0rem;
  }
  .connectors-container {
    display: flex;
    flex-wrap: wrap;

    .connectors-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      margin-top: 8px;
      padding-top: 25px;
      padding-left: 0;
      margin-left: -10px;
      background-color: #265858;
      font-weight: 70px;
      border-radius: 25px;
      padding: 4px;
      .status {
        height: 1.1rem;
        width: 1.1rem;
        border-radius: 50%;
        margin: 0.23rem;
      }
      .available {
        background-color: #7ac142;
      }
      > img {
        height: 1.1rem;
        width: 1.1rem;
        border-radius: 50%;
        margin: 0.23rem;
      }
    }
  }

  // .charging-station-cell {
  // display: flex;
  // align-items: center;
  // padding: 1.2rem 0rem;
  // > p {
  //   font-weight: 500;
  //   margin-left: 15px;
  // }
  // }

  .connectors-cell {
    display: flex;
    align-items: center;

    > p {
      font-weight: 500;
      margin-left: 3px;
    }
  }

  .charging-station-table {
    height: 100%;
    padding: 1.2em 0em;
    flex-wrap: wrap;
    padding: 0.8rem 1.7rem;
  }

  .column-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 2.5em;
    margin-top: 1.3em;
  }

  .column-title {
    font-size: 1.1em;
    margin-bottom: 0;
  }
  .column-sub-title {
    color: #7ac142;
    font-size: 0.8em;
  }
}
