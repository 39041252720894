:root {
  ---font-size: 0.8em;
  ---text-color: #bcbcbc;
}

.pagination-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.2em;

  .select-rows-dropdown {
    margin-top: 0.6rem;
    display: flex;
    align-items: center;


    > p {
      color: white;
      font-size: 0.85rem;
    }
    &.light{
      p{
        color: black;
      }
    }

    .rows-dropdown {
      color: var(---text-color);
      padding: 0.2em 0.5em;
      border: 1px solid var(---text-color);
      border-radius: 6px;
      margin-left: 0.6rem;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      > p {
        margin-right: 7px;
      }

      .select-rows {
        position: absolute;
        top: -8.5rem;
        left: 0;
        background-color: #0b3d3c;
        border-radius: 4px;
        cursor: pointer;

        > p {
          padding: 2.7px 16px;
        }
      }
    }
  }

  .pagination {
    list-style: none;
    display: flex;
    .page-item:first-child a {
      border-width: 0;
      color: white;
    }

    .page-item:first-child .page-link {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      background-color: transparent;
    }
    .page-item:last-child a {
      border-width: 0;
      color: white;
    }
  }

  .active {
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0) !important;
    background: rgba(122, 193, 66, 0.2) !important;
    border: 1px solid #fffd78 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    color: white !important;
  }

  .disabled {
    background-color: transparent !important;
    color: grey !important;
  }

  .page-link {
    font-size: var(---font-size) !important;
    font-weight: 800 !important;
    margin-left: 1em !important;
    cursor: pointer !important;
    background-color: transparent;
    color: var(---text-color);
    padding: 0.2em 0.7em;
    border: 1px solid var(---text-color);
    box-sizing: border-box;
    border-radius: 6px;

    &:hover {
      color: var(---text-color) !important;
      background-color: transparent !important;
    }
  }
}

@media only screen and (max-width: 760px) {
  .pagination-div {
    margin-top: 1em;
    padding: 0;
    flex-direction: column;
    justify-content: space-around;
    .select-rows-dropdown{
      margin-bottom: 1em;
    }
    ul{
      padding: 0;
    }
  }
}
