@import "../../styles/colors.scss";

:root {
  --border-radius-circle: 50px;
  --border-radius-square: 6px;
}

.button-div {
  margin: auto;
  display: flex;
  background-color: $primary;
  border-style: none;
  border-radius: var(--border-radius-circle);
  padding: 0.6em 1.2em;
  cursor: pointer;

  &:hover {
    background-color: #004443;
  }

  &[data-disabled="true"] {
    background-color: #003d3d;
    cursor: default;

    > p {
      color: #507a7a;
    }
  }

  // outline
  &[data-outline="true"] {
    background-color: transparent;
    border: 2px solid white;
    padding: 0.5em 1.2em;

    &:hover {
      border: 2px solid $primary;
    }

    // disabled
    &[data-disabled="true"] {
      border: 2px solid #507a7a;

      > p {
        color: #507a7a;
      }
    }
  }

  > p {
    font-weight: 600;
    color: white;

    // size
    &[data-size="sm"] {
      font-size: 0.8em;
    }

    &[data-size="default"] {
      font-size: 0.95em;
    }

    &[data-size="lg"] {
      font-size: 1.1em;
    }
  }
}
