.avatar {
  //   sizes
  display: flex;

  img {
    object-fit: cover;
    border-radius: 50%;
    height: 65px;
    width: 65px;
  }
}
