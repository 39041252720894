@import "../../styles/colors.scss";

.page {
  height: 100vh;
  background-color: #003131;
  display: flex;
  position: relative;
  .two-sidebars {
    display: flex;
  }

  .bottom-text {
    color: #d1d5db;
    text-align: center;
    padding: 2.2rem 0rem 1rem 0rem;
    font-size: 0.75em;
  }
  .page-right-div {
    overflow: hidden;
    width: 100%;
    padding: 20px 32px;
  }
}

@media only screen and (max-width: 992px) {
  .page {
    padding-right: 0;
    .screen-content {
      &[data-is-sidebar-open="true"] {
        opacity: 0.3;
        overflow-y: hidden;
      }
    }
  }
}
