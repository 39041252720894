@import "../../styles/colors.scss";

.edit-modal {
  .top-div {
    display: flex;

    img {
      height: 45px;
      width: 45px;
      border-radius: 50%;
      object-fit: cover;
    }

    .upload-avatar {
      margin-left: 0.6rem;

      .button-div {
        margin: 0px;
        border: 2px solid rgba(63, 81, 181, 0.5);
        box-sizing: border-box;
        border-radius: 8px;

        > p {
          color: $primary;
          font-size: 0.9em;
        }
      }

      .recommend-text {
        color: #506176;
        font-size: 0.78em;
        margin-top: 0.5rem;
      }
    }
  }
  .action-buttons {
    display: flex;
    margin-top: 1.2rem;
    align-items: center;
    justify-content: flex-start;

    .button-div {
      margin: 0px;
    }

    :first-child.button-div {
      margin: 0px;
      margin-right: 0.5rem;
      background-color: transparent;
      border: 2px solid $primary;
      > p {
        color: $primary;
      }
    }
  }
  .error {
    color: red;
    margin-top: 4px;
    margin-left: 15px;
    font-size: 0.8em;
  }
  .center-file {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Dropdown-control {
    width: 97%;
    border-radius: 10px;
    font-size: 0.9em;
    margin-top: 15px;
    color: #4d6272;
    padding: 0.8em 0.6em;
  }
  .country {
    width: 97%;
    margin-top: 15px;
  }
}
