@import "../../styles/colors.scss";

:root {
  --minus-margin-left: -3em;
  ---font-size: 0.8em;
  ---text-color: #bcbcbc;
}

// Table css override
.sc-dlVxhl {
  border-radius: 12px !important;
  overflow-x: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow: hidden;

  // custom scrollbar
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #002726;
  }

  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0.4em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffffffbb;
  }
}

// to minimize space between type and location col
.rdt_TableHeadRow {
  border-bottom-width: 2.5px !important;
  background-color: #042726 !important;

  // .rdt_TableCol {
  //   &[data-column-id="2"] {
  //     margin-left: var(--minus-margin-left);
  //   }
  // }
}

// .rdt_TableCell {
//   &[data-column-id="2"] {
//     margin-left: var(--minus-margin-left);
//   }
// }

// border top row radius
.rdt_TableBody {
  background-color: #042726 !important;
  overflow: hidden !important;
}

.rdt_TableRow {
  padding: 1.3em 0em;

  // cursor: default !important;
  &:hover {
    cursor: pointer;
    background-color: #005150 !important;
  }

  &:first-child {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }
}

.location-container {
  height: 100%;

  .table-div {
    position: relative;
    height: 100%;
    padding: 1.2em 0em;
    flex-wrap: wrap;
    padding: 0.8rem 1.7rem;
    .two_dots {
      width: 25px;
      position: absolute;
      top: 4rem;
      right: 23rem;
      z-index: 10000;
      .table-settings {
        width: 23rem;
        height: 450px;
        overflow: auto;
        background-color: #002626;
        border: 0.5px solid #113d2b;
        border-radius: 10px;
        padding: 25px;
        box-sizing: border-box;
        color: white;
        h4 {
          margin-bottom: 15px;
        }
        .table-columns {
          display: flex;
          .options-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
            margin-bottom: 10px;
            .option {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              margin-right: 10px;
              p {
                margin: 0 10px;
                font-size: 12px;
              }
            }
          }
        }
        .divider {
          background-color: white;
          width: 100%;
          height: 0.3px;
          opacity: 0.7;
          margin: 15px 0px 10px 0px;
        }
        .quick-options {
          h4 {
            line-height: 28px;
            padding: 0 10px;
            cursor: pointer;
          }
          h4:hover {
            background-color: #113d2b;
            border-radius: 20px;
          }
        }
      }
    }
  }
  .export_button_div {
    position: absolute;
    right: 3rem;
    button {
      margin: auto;
      width: 64px;
      background-color: #003d3d;
      border-style: none;
      border-radius: var(--border-radius-circle);
      padding: 0.6em 1.5em;
      cursor: pointer;
      color: white;
      font-size: 0.88em;
    }
  }
  .options_dots{
    padding-right:10px;
  }
  .column-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 2.5em;
    margin-top: 0.5em;
  }

  .column-title {
    font-size: 1em;
    margin-bottom: 0;
  }

  .column-sub-title {
    color: #7ac142;
    font-size: 0.8em;
  }
}

.hideEdit {
  display: none;
}
.restore {
  button {
    margin: auto;
    width: 64px;
    background-color: #005150;
    border-style: none;
    border-radius: var(--border-radius-circle);
    padding: 5px;
    cursor: pointer;
    color: white;
  }
}
