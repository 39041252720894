.api-log {
  box-sizing: border-box;
  .title-div {
    flex: 1;
  }
  .input-div {
    flex: 0.3;
    border-radius: 999px;
    margin-right: 2rem;
  }
  .pagination-div {
    padding: 15px 30px;
    width: 100%;
  }

  .table-div {
    padding: 15px 30px;
    overflow-x: auto;
    .pending {
      width: 100%;
      background-color: #003b3b;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      color: white;
      font-size: 24px;
      font-weight: 700;
      padding: 24px;
    }
    .no-records {
      width: 100%;
      background-color: #003b3b;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      color: white;
    }
    table {
      border-collapse: collapse;
      width: 100%;
      color: white;
      min-width: 1300px;
      td {
        padding: 15px;
        border: 2px solid #003b3b;
        th {
          text-align: left;
        }
      }
    }
  }
}
