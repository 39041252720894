.rdt_TableCol_Sortable {
  margin-left: 0px;
}

// .table-div tbody > tr > td {
//   padding-left: 0 !important;
// }

.table {
  overflow-x: scroll;

  .exception {
    display: flex;
    margin-top: 40px;
    width: 100%;
    height: 90px;
    border-radius: 10px;
    background-color: #405c5c;
    color: white;

    .exceptionDescription {
      width: 75%;
      font-size: 11px;
      padding-top: 10px;
      padding-left: 10px;
    }
  }

  .newExceptionActions {
    display: flex;
    column-gap: 10px;
  }

  .rdt_Table {
    border-left: 1px solid #005150 !important;
    border-right: 1px solid #005150 !important;
  }

  .rdt_TableRow {
    padding: 1.3em 0em;
    background-color: #002626 !important;
    border-bottom: 1px solid #005150 !important;
    padding: 18px 0px !important;

    // cursor: default !important;
    &:hover {
      cursor: pointer;
      background-color: #005150 !important;
    }

    &:first-child {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }

    &:last-child {
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }

  .rdt_TableHeadRow {
    background-color: #005150 !important;
  }

  input {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    width: 100px;
    font-family: "Poppins", sans-serif;
  }

  .buttonEdit {
    background: transparent;
    color: white;
    outline: none;
    border: none;
    width: fit-content;
    font-size: 20px;
    font-weight: 700;
  }

  .updateIcon {
    width: 40px;
    display: flex;
    justify-content: center;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
    font-size: 20px;
  }
}

.calendar {
  display: flex;
  justify-content: flex-end;
  position: relative;

  .selected-date {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .month {
      color: var(---font-color);
      font-weight: 700;
      font-size: 1.4rem;
      margin: 0px 5px 0px 0px;
    }

    .date {
      color: var(---font-color);
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  margin-top: 0.2em;
  cursor: pointer;

  >img {
    width: 0.6em;
    height: 0.6em;
  }
}

.date-select-modal{
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #00000050;
}