.react-calendar {
  background-color: #002626;
  border: 0.7px solid #083128;
  border-radius: 10px;

  .react-calendar * {
    color: white;
    text-decoration: none;
  }

  .react-calendar__tile:disabled {
    background-color: #005150;
     * {
      color: #868686;
    }
  }
  .react-calendar__tile--now {
    background-color: #76bc43;
  }

  .react-calendar__tile--active {
    background-color: #76bc43 !important;
    border-radius: 10px;
  }
  .react-calendar__tile--hoverStart {
    background-color: #76bc43 !important;
    border-radius: 17px 0px 0px 17px;
    color: black !important;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #005150;
  }
  .react-calendar__tile--hoverEnd {
    background-color: #76bc43 !important;
    border-radius: 0px 17px 17px 0px;
  }
  .react-calendar__navigation button:disabled {
    background-color: #002626;
  }
  .react-calendar__navigation button {
    background-color: #002626 !important;
  }
  .react-calendar__year-view .react-calendar__tile:hover {
    background-color: #005150;
  }
  .react-calendar__decade-view .react-calendar__tile:hover {
    background-color: #005150;
  }
  .react-calendar__century-view .react-calendar__tile:hover {
    background-color: #005150;
  }
  .react-calendar__month-view__days__day:hover {
    background-color: #005150;
  }
  .react-calendar__navigation__prev-button {
    background-color: #002626 !important;
  }
  .react-calendar__navigation__arrow:hover {
    background-color: #005150 !important;
  }
  .react-calendar__navigation__arrow {
    border-radius: 10px;
  }
  .react-calendar__navigation__label:hover {
    background-color: #005150 !important;
  }
}
