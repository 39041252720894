.notifications-container {
  
  .title-div {
    flex: 1;
  }

  .input-div {
    flex: 0.3;
    border-radius: 999px;
    margin-right: 2rem;
  }

  .top-bar-div {
    margin-left: 1.9rem;
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;

    .bar-div {
      margin-right: 1em;
      margin-top: 0.5em;
      > p {
        color: white;
        font-size: 0.75em;
        font-weight: 500;
        padding: 0 0.8em;
      }

      .bar-line {
        margin-top: 0.35em;
        height: 2px;

        &[data-line-color="Pending"] {
          background-color: #ebebeb;
        }
        &[data-line-color="Accepted"] {
          background-color: #7ac142;
        }
        &[data-line-color="Rejected"] {
          background-color: #fcb623;
        }
      }
    }
  }
  .main-div {
    padding: 1rem 3rem 0.3rem 4rem;
  }

  .titles-div {
    display: flex;
    justify-content: space-between;

    > h4 {
      color: white;
    }

    :last-child {
      margin-right: 1rem;
    }

    .notifications-div {
      padding: 0rem 3rem 1rem 3.5rem;
    }
  }
}
