.default-img-div {
  border-radius: 50%;
  background-image: linear-gradient(#6cb14f, #3b7d6a);
  display: flex;
  justify-content: center;
  align-items: center;
  .inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 87%;
    height: 87%;
    border: 4px solid white;
    background-color: #adadad;
    color: #045352;
    font-weight: bold;
  }
}

.sizeBig {
  width: 65px;
  height: 65px;
}
.sizeSmall {
  min-width: 50px;
  height: 50px;
}
