.buttons-container {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0 4rem;
}


.ReactModal__Overlay {
  overflow: hidden;
  background-color: rgba(0,0,0,0.4) !important;
}