.status-tag {
  padding: 0.3rem 0.5rem;
  border: 1px solid #DC143C;
  border-radius: 8px;
  align-self: center;
  width: max-content;

  background-color: #003131;
  p {
    font-size: 0.7rem;
    margin: 0px;
    color: #DC143C
  }

  &[data-status="live"] {
    border: 1px solid #76BC41;

    p {
      color: #76BC41;
    }
  }

  &[data-status="pending"] {
    border: 1px solid #fff;

    p {
      color: #fff;
    }
  }

  &[data-status="in construction"] {
    border: 1px solid #FFB200;

    p {
      color: #FFB200;
    }
  }

  &[data-status="in planning"] {
    border: 1px solid #E7EA71;

    p {
      color: #E7EA71;
    }
  }
}
