.edit-dropdown {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2em;

  .input-label {
    color: #34495f;
    font-size: 0.8em;
    margin-top: 1.5em;
    margin-left: 0px !important;
  }

  .edit-input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    margin-top: 0.4em;
    padding: 0.15em 0em;
  }
  .dropdown-react {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 0.4em;
  }
}
