.dropdown-div {
  width: 100%;
  margin-top: 0.4em;
  background-color: #ffffff;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 10px;
}

.Dropdown-control {
  width: 100%;
  border-radius: 10px;
  font-size: 0.8em;
  color: #4d6272;
  padding: 0.8em 0.6em;
  &.Dropdown-disabled {
    background-color: #efefef;
  }
}
