@import "../../styles//colors.scss";
@import "../../styles/unitConverter.scss";

:root {
  --column-gap: 2em;
  --row-gap: 1.6em;
  --border-radius: 20px;
  --padding-horizontal: 1.7rem;
}

.network-container {
  height: 100%;
  padding-bottom: 1.6em;
  .chart-content {
    display: grid;
    grid-template-columns: 1fr 1fr 0.6fr;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    align-self: start;
    padding: 1.6rem var(--padding-horizontal) 0rem var(--padding-horizontal);
    column-gap: var(--column-gap);
    margin-bottom: var(--row-gap);
  }

  .announcement-cards-div {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 1em;
    height: 100%;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .announcement-card {
      display: flex;
      align-items: center;
      height: max-content;
      padding: 1em 4.5em 1em 2em;
      background-color: $primary;
      border-radius: 15px;
      cursor: pointer;

      > img {
        height: 3.4em;
        width: 3.4em;
      }

      .announcement-text {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        padding-left: 2em;

        :first-child {
          color: white;
          font-size: 1.2rem;
        }

        :last-child {
          color: #bababa;
          font-size: 0.9em;
          margin-top: 0.4em;
        }
      }
    }

    .announcement-card-1 {
      background: radial-gradient(
        97.57% 210.75% at 0.9% 2.98%,
        rgba(98, 162, 61, 0.4) 0%,
        rgba(255, 255, 255, 0.03) 100%
      );

      backdrop-filter: blur(42px);
    }

    .announcement-card-2 {
      background: radial-gradient(
        97.57% 210.75% at 0.9% 2.98%,
        rgba(252, 182, 35, 0.4) 0%,
        rgba(255, 255, 255, 0.03) 100%
      );
      backdrop-filter: blur(42px);
      p {
        color: white;
        padding-left: 2em;
      }
    }
  }

  .center-content {
    display: grid;
    padding-right: var(--padding-horizontal);
    padding-left: var(--padding-horizontal);
    grid-template-columns: 0.6fr 1fr;
    column-gap: var(--column-gap);
    pad .display-div {
      display: none;
    }

    .bg-img {
      border-radius: var(--border-radius);
      overflow: hidden;
      background-image: url("../../assets/images/location-bg-image.svg");
      background-repeat: no-repeat;
      cursor: pointer;

      .bg-overlay {
        display: grid;
        grid-template-columns: 0.3fr 0.6fr;
        border-radius: var(--border-radius);
        overflow: hidden;
        height: 100%;
        width: 100%;
        background: rgba(0, 88, 88, 0.02);
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(10px);
        justify-content: center;
        align-items: center;

        .icon-view {
          display: flex;
          flex-direction: column;
          align-items: center;

          > img {
            width: clamp(2.3rem, 2.5vw, 3.5rem);
            height: clamp(2.3rem, 2.5vw, 3.5rem);
          }

          > h6 {
            color: white;
            margin-top: 0.8em;
          }
        }

        .desc {
          display: flex;
          align-items: flex-start;
          > p {
            color: white;
            font-size: 0.8em;
            text-align: center;
          }
        }
      }
    }
  }

  .info-cards {
    display: grid;
    grid-template-columns: 0.76fr 0.76fr 1fr;
    column-gap: var(--column-gap);
    overflow-x: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .info-card {
      display: flex;
      flex-flow: column;
      align-items: center;
      background-color: $primary;
      padding: 2em 1em 0.6em 1em;
      border-radius: var(--border-radius);
      cursor: pointer;

      > h6 {
        color: white;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
      }

      > img {
        width: 3.5em;
        height: 3.5em;
        margin-top: 1em;
      }

      > p {
        line-height: 25px;
        text-align: center;
        color: #bababa;
        margin-top: 1em;
      }
    }
  }

  .bottom-content {
    display: grid;
    grid-template-columns: 2.8fr 1fr;
    column-gap: var(--column-gap);
    padding-right: var(--padding-horizontal);
    padding-left: var(--padding-horizontal);
  }
}

@media only screen and (max-width: 1635px) {
  .network-container {
    .chart-content {
      grid-template-columns: repeat(2, 1fr);
    }

    .center-content {
      grid-template-columns: 2.8fr 1fr;
      margin-bottom: var(--row-gap);
    }
  }
}

@media only screen and (max-width: 1320px) {
  .network-container {
    .chart-content {
      grid-template-columns: repeat(1, 1fr);
      row-gap: var(--row-gap);
    }
    .bottom-content {
      grid-template-columns: 1fr;
      row-gap: var(--row-gap);
    }
  }
}

@media only screen and (max-width: 992px) {
  .network-container {
    .chart-content {
      display: block;
      // flex-wrap: nowrap;
      // grid-template-columns: repeat(1, 1fr);
      // row-gap: var(--row-gap);
      // flex: 0 0 40% !important;
      // padding: 1rem 1.2rem;
      // margin-bottom: 0.8rem;
    }

    .center-content {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      justify-content: space-between !important;
      row-gap: var(--row-gap);
      .bg-img {
        height: 17em;
        margin-left: -9px;
      }
    }

    .announcement-cards-div {
      display: flex;
      row-gap: var(--row-gap);
      margin-bottom: var(--row-gap);
      column-gap: 1rem;
      overflow-x: auto;
    }

    .bottom-content {
      grid-template-columns: 1fr;
      row-gap: var(--row-gap);

      .info-cards {
        column-gap: 1.1rem;
        .info-card {
          width: 16rem;
        }
      }
    }
  }
}
