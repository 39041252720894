@import "../../styles/colors.scss";

.user-management-container {
  height: 100%;

  .user-management-table {
    height: 100%;
    padding: 1.2em 0em;
    flex-wrap: wrap;
    padding: 0.8rem 1.7rem;

    // table css override

    .fSQziN {
      border-radius: 0px !important;
    }

    .eNTwEj {
      background-color: white;
      color: $primary;
    }

    .guiKPx {
      background-color: #f4f4f4;
      color: $primary;
    }

    // border top row radius
    .rdt_TableBody {
      background-color: #eeeeee !important;
    }

    .rdt_TableHeadRow {
      border-bottom-width: 0px !important;
      background-color: #eeeeee !important;
      color: $primary;
      font-weight: bold;
    }

    .rdt_TableRow {
      padding: 1.3em 0em;
      cursor: default !important;
      &:hover {
        background-color: #005150a1 !important;
      }

      &:first-child {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important ;
      }
    }

    .guiKPx:not(:last-of-type) {
      border-bottom-width: 2px;
      border-bottom-color: #f4f4f4;

      &:hover {
        background-color: red !important;
      }
    }

    .eNTwEj:not(:last-of-type) {
      border-bottom-width: 2px;
      border-bottom-color: #f4f4f4;
    }

    // -----------------
  }
  .edit_image {
    img {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
}
