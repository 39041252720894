.performance-card {
  display: flex;
  height: 100%;

  .bg-img {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // background-image: url("../../assets/images/blur-image.svg");
    background-repeat: no-repeat;
    background: radial-gradient(
      102.57% 164.45% at 2.17% 0%,
      rgba(255, 255, 255, 0.1) 39.06%,
      rgba(255, 255, 255, 0.009) 100%
    );
    filter: drop-shadow(1px 2px 10px rgba(0, 0, 0, 0.005));
    backdrop-filter: blur(20px) !important;
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
    padding: 1rem 2rem 2rem 2rem;

    .top-header {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 0.7em;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);

      > img {
        height: clamp(14px, 2.5vw, 1.2rem);
        width: clamp(14px, 2.5vw, 1.2rem);
      }

      > h6 {
        color: white;
        margin-left: 0.5em;
        font-weight: bold;
        font-size: clamp(17px, 2.5vw, 1.05rem);
      }

      .header-right-icons {
        display: flex;
        position: absolute;
        right: 0;

        img {
          cursor: pointer;
          height: clamp(14px, 2.5vw, 1.2rem);
          width: clamp(14px, 2.5vw, 1.2rem);
        }

        :first-child {
          margin-right: 0.8em;
        }
      }
    }

    .progress-div {
      display: grid;
      grid-template-columns: 0.4fr 0.6fr;
      padding-top: 1em;

      .left-content {
        display: flex;
        flex-direction: column;
      justify-content: space-around;
        .progress-bar + .progress-bar {
          margin-top: 1em;
        }

        .progress-bar {
          width: 100%;
          background-color: transparent;
          .progress-label {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            > p {
              color: white;
            }
            :first-child {
              font-size: clamp(14px, 2.5vw, 0.9rem);
            }

            :last-child {
              font-size: clamp(14px, 2.5vw, 0.9rem);
              font-weight: 700;
            }
          }
        }
      }
      .right-content {
        display: flex;

        padding-left: 3vw;
        width: 100%;
        height: 100%;

        .innerWrapperDiv {
          position: relative;
          display: flex;

          .locations-number {
            position: absolute !important;
            z-index: 99 !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 32px;
            font-weight: bold;
            
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1320px) {
  .performance-card {
    .bg-img {
      .progress-div {
        .left-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .progress-bar {
            width: 100%;
            background-color: transparent;
            .progress-label {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1em;
              > p {
                color: white;
              }
              :first-child {
                font-size: clamp(14px, 2.5vw, 0.9rem);
              }

              :last-child {
                font-size: clamp(14px, 2.5vw, 0.9rem);
                font-weight: 700;
              }
            }
          }
        }
        .right-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: start;
        }
      }
      .right-content {
        flex-basis: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .performance-card {
    .bg-img {
      padding: 1rem 1rem 2rem 1rem;
      .progress-div {
        .left-content {
          min-width: 7.5rem;
          justify-content: space-around;
          .progress-bar + .progress-bar {
            margin-top: 0.5em !important;
          }

          .progress-bar {
            width: 100%;
            background-color: transparent;
            .progress-label {
              margin-bottom: 0.2em;
            }
          }
        }
      }
      .right-content {
        min-width: 9rem;
        display: flex;
        align-self: center;
        width: 90% !important;
        height: 100% !important;
        margin-top: 1rem;
      }
    }
  }
}
