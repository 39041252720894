.input-wrapper {
  background-color: #ffffff;
  border: 0.3px solid #b1b1b1;
  box-sizing: border-box;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0px 0em 0px 0px;
  position: relative;

  &[data-disabled="true"] {
    background-color: #ebebeb;
  }

  &[data-error="true"] {
    border: 2px solid red !important;
  }

  .input {
    font-size: 0.8em;
    padding: 0.8em 0.6em;
    color: #4d6272;
    width: inherit;
    border-radius: 10px;
    border-style: none;

    &:focus {
      outline: none;
    }
  }
}

.error-text {
  color: red;
  margin-top: 4px;
  margin-left: 15px;
  font-size: 0.8em;
}
.iconImage {
  position: absolute;
  right: 1%;
}
