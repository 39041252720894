@import "../../styles//colors.scss";

.widget {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border: 2px solid $secondary;
  border-radius: 0.6rem;
  width: max-content;
  cursor: pointer;
  &[data-is-sidebar-open="false"] {
    border: 0px;
  }

  &[data-is-sidebar-open="true"] {
    padding: 18px;
    &:hover {
      background-color: $widget_hover;
    }
  }

  .user-info {
    margin-left: 10px;
    margin-right: 8px;
    :first-child {
      color: white;
      font-size: 14px;
      font-weight: 600;
    }
    :last-child {
      color: white;
      font-size: 12px;
      font-weight: 500;
      margin-top: 6px;
    }
  }

  img {
    height: 20px;
    width: 20px;
  }
}
