header {
  display: flex;
  align-items: center;
  .display {
    display: flex;
    font-weight: Black 900;
  }

  img {
    cursor: pointer;
  }

  .sidebar-open-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left-content {
      display: flex;
      align-items: center;
      .openSidebar {
        width: 64px;
        height: 64px;
        display: flex;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        background-color: #002726;
        border-radius: 5px;
        cursor: pointer;
        .sidebar-icon {
          height: 24px;
          width: 24px;
          margin: 20px;
        }
      }
      &[data-is-sidebar-open="false"] {
        h4 {
          display: none;
        }
      }


      h4 {
        color: white;
        font-weight: 900;
        font-size: 1.05rem;
        margin-left: 1.8rem;
      }
    }

    .right-content {
      display: flex;
      align-items: center;
      .notificationDiv {
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #002726;
        border-radius: 5px;
        margin-left: 20px;
        cursor: pointer;
        img {
          height: 24px;
          width: 24px;
        }
      }
      h4 {
        color: white;
        font-weight: 900;
        font-size: 1.05rem;
        margin-left: 1.8rem;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .display {
    display: none !important;
  }

  .sidebar-open-header {
    .right-content {
      img {
        margin-right: 0rem !important;
        // margin-left: 3rem;
      }
    }
  }
}
