@import "../../styles/colors.scss";

.edit-modal {
  .top-image-div {
    display: flex;

    .upload-avatar {
      margin-left: 0.6rem;

      .button-div {
        margin: 0px;
        border: 2px solid rgba(63, 81, 181, 0.5);
        box-sizing: border-box;
        border-radius: 8px;

        > p {
          color: $primary;
          font-size: 0.9em;
        }
      }

      .recommend-text {
        color: #506176;
        font-size: 0.78em;
        margin-top: 0.5rem;
      }
    }
  }
  .action-buttons {
    display: flex;
    margin-top: 1.2rem;
    align-items: center;
    justify-content: flex-start;

    .button-div {
      margin: 0px;
    }

    :first-child.button-div {
      margin: 0px;
      margin-right: 0.5rem;
      background-color: transparent;
      border: 2px solid $primary;
      > p {
        color: $primary;
      }
    }
  }
}
