.input-div {
  display: flex;
  flex: 1;
  align-self: center;
  align-items: center;
  background-color: #002626;
  height: 64px;
  margin: 0 20px 0 0;
  border-radius: 7px;
  padding: 0 0.8rem;
  box-sizing: border-box;
  border: 0;

  .search-icon {
    height: 19px;
    width: 19px;
  }

  input {
    display: flex;
    flex: 1;
    height: 100%;
    background-color: transparent;
    border-style: none;
    padding: 0px 10px;
    outline: none;
    color: white;
    font-size: 15px;

    &::placeholder {
      color: white;
      opacity: 0.5;
      font-size: 15px;
    }
  }
}
