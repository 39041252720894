:root {
  ---label-color: #555555;
  ---margin-top: 0.28em;
  ---border-radius: 16px;
}

.pending {
  width: 100%;
  background-color: #003b3b;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  padding: 24px;
}

.edit-location-container {
  height: 100%;
  .edit-main-div {
    position: relative;
    padding: 0.8em 1.7em;
    height: 100%;

    .top-tabs {
      display: flex;
      align-items: center;
      padding: 0rem 2rem;

      .tab {
        margin-right: 3em;
        cursor: pointer;

        >p {
          &[data-active-tab="true"] {
            color: white;
          }

          font-size: 0.8em;
          color: rgba(255, 255, 255, 0.5);
          font-weight: 500;
        }
      }
    }

    .wrapper-div {
      background-color: #002726;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 1em;
      padding: 1.4em;
      border-radius: 8px;
      height: 100%;
      overflow-x: auto;

      .inner-div {
        background-color: white;
        border-radius: var(---border-radius);
        padding: 2em;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }

      .heading-text {
        font-weight: 500;
        font-size: 1.1em;
      }

      .details {
        display: flex;
        flex-wrap: wrap;

        .left-content {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          flex: 1;
          padding-right: 20px;
          width: 100%;

          .label {
            // margin-top: 0.7em;
            margin-bottom: 0.3em;
            font-size: 0.85em;
            font-weight: 450;
            color: var(---label-color);
          }

          .status-button {
            align-self: flex-start;
            margin-top: 0.3em;
            margin-bottom: 0.7em;
          }

          .val-text {
            color: #555555;
            font-size: 0.85em;
            opacity: 0.5;
            margin-bottom: 20px;
          }

          .view-options {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 0.3em;

            .manage-text {
              font-weight: 600;
              font-size: 20px;
              cursor: pointer;
              margin-top: 20px;
              width: 187px;
              height: 24px;
            }
          }

          .all-connector-status {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin: 20px;

            .connector-status-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-right: 20px;
              margin-top: 8px;
              padding-top: 25px;
              padding-left: 0;
              margin-left: -5px;
              background-color: #265858;
              font-weight: 70px;
              border-radius: 25px;
              padding: 4px;

              >img {
                margin: 5px;
              }

              .status {
                border-radius: 50px;
                width: 25px;
                height: 25px;
                margin: 5px;
              }

              .available {
                background-color: #7ac142;
              }
            }
          }

          >.button {
            padding: 0.25em 0.4em;
            margin-top: var(---margin-top);
            height: 32px;
            font-weight: 58px;

            >p {
              font-size: 0.75em;
            }
          }
        }

        .right-content {
          display: flex;
          flex-direction: column;
          flex: 2;
          width: 100%;

          .topLngLat {
            display: flex;
            flex-wrap: wrap;

            .lngLatContainer {
              .lngLat {
                font-weight: 500;
              }

              display: flex;
              margin-right: 20px;
              margin-bottom: 10px;
              padding: 10px 20px;
              font-size: 14px;
              background-color: #ebebeb;
              border-radius: 10px;
              border: 2px solid #e6e6e6;
            }

            margin-bottom: 10px;
          }

          // height: 100%;
          // overflow-y: hidden;
          // justify-content: flex-end;

          .map {
            display: flex;
            border-radius: var(---border-radius);
            height: 507px;
            // background-color: #ebebef;
            // background: url("../../assets/images/map.svg") no-repeat;
            // background-size: 100%;
            overflow: hidden;
          }
        }
      }

      .charging-stations {
        >h6 {
          font-weight: bold;
          color: #005150;
          margin-top: 1.2em;
        }

        .station {
          margin-top: 1em;
          background-color: white;
          padding: 0.9em 1.8em;
          border-radius: 10px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          &[data-status="deleted"] {
            opacity: 0.5;
          }

          .station-name {
            display: flex;
            align-items: center;
            min-width: 20% !important;

            .location-marker {
              height: 2.1em;
              width: 2.1em;
            }

            .name {
              color: #333333;
              font-weight: 600;
              font-size: 1.1em;
              margin-left: 0.4em;
            }
          }

          .station-id {
            color: #7ac142;
            font-size: 0.85em;
            align-self: center;
            min-width: 20% !important;
          }

          .connectors {
            display: flex;
            align-items: center;
            min-width: 20% !important;

            .connector-text {
              color: #333333;
              font-size: 0.85em;
              font-weight: 500;
              margin-right: 0.4em;
            }

            .connector-icon {
              height: 1em;
              width: 1em;
              margin-left: 0.7em;
              cursor: pointer;
            }
          }

          .date {
            display: flex;
            align-items: center;
            min-width: 20% !important;

            .date-label {
              color: #333333;
              font-size: 0.85em;
              font-weight: 500;
              margin-right: 0.4em;
            }

            .date-text {
              font-size: 0.75em;
              opacity: 0.7;
            }
          }
        }
      }

      .desc {
        font-size: 0.7em;
        margin-top: 1.6em;
        color: #787878;
      }

      .additional {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .left-content {
          padding-right: 6em;
        }

        .inputs-div {
          margin-top: 1em;
          display: flex;
          flex-direction: column;
          row-gap: 0.9em;

          .input-and-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 10em;

            &[data-flex-start="true"] {
              justify-content: flex-start;
            }

            .upload-btn-wrapper {
              position: relative;
              overflow: hidden;
              display: inline-block;

              & input[type="file"] {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
              }

              .upload-image-btn {
                height: 100%;
                cursor: pointer;
                border: 1px solid #3a3a3a;
              }

              .location-image {
                height: 90px;
                width: 150px;
                object-fit: cover;

                &.null-image {
                  object-fit: contain;
                }
              }
            }

            .label {
              font-size: 0.75em;
              min-width: 12.5em;
            }
          }
        }
      }

      .my-business {
        margin-top: 1.4em;
        background-color: white;
        padding: 0.9em 1.8em;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .business-title {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .google-text {
            font-size: 0.9em;

            margin-right: 6px;
            font-weight: 500;
          }

          .business-text {
            font-size: 0.9em;
            margin-right: 0.9em;
          }

          .integration-text {
            font-size: 0.9em;
            font-weight: 400;
          }
        }

        .info-text {
          margin-top: 0.5em;
          font-size: 0.75em;
          color: #6b7280;
        }
      }

      .flex-row {
        display: flex;
        align-items: center;
      }

      .charging-stations-list {
        padding: 0em 0.6em;

        .charging-station-heading {
          color: #005150;
          font-weight: 700;
          font-size: 1.05em;
        }

        table {
          border-spacing: 0px;
        }

        .table-div {
          display: block;
          overflow-x: auto;
          white-space: nowrap;
          margin-top: 1em;

          .charging-stations-table {
            width: 100%;
          }

          .top-header {
            >tr {
              :first-child {
                padding-left: 1.3em;
              }

              >th {
                color: #005150;
                font-size: 0.85em;
                font-weight: 500;
                text-align: start;
              }
            }
          }

          .center-header {
            .connector-status-wrapper {
              display: flex;
              justify-content: center;
              flex-direction: row;
              align-items: center;
              margin-right: 20px;
              margin-left: 17px;
              padding-top: 25px;
              padding-left: 0;
              background-color: #265858;
              font-weight: 70px;
              border-radius: 25px;
              padding: 4px;

              >img {
                margin: 5px;
              }

              .status {
                border-radius: 50px;
                width: 25px;
                height: 25px;
                margin: 5px;
              }

              .available {
                background-color: #7ac142;
              }
            }

            .app-logo {
              margin-left: 0.5em;
              margin-right: 0.6em;
            }

            .header-icon {
              height: 1.4em;
              width: 1.4em;
            }

            .icon-text {
              margin-right: 1.5em;
              margin-left: 0.3em;
              font-size: 0.8em;
              color: white;
              font-weight: 600;
            }

            >tr {
              .connectors {
                display: flex;
              }

              .icon-left {
                text-align: right;
                padding-right: 46px;
                // padding: 0.85em 0em 1em 6.5em;
              }

              >th {
                &:first-child {
                  min-width: 18rem;
                  padding: 20px;
                }

                &:nth-child(2) {
                  min-width: 20rem;
                }

                color: #d9d8e9;
                background-color: #003131;
                font-size: 0.85em;
                font-weight: 500;
                text-align: start;

                >img {
                  width: 4.2em;
                  height: 4.2em;
                }

                >p {
                  font-size: 0.95em;
                  font-weight: 500;
                }
              }
            }
          }

          .bottom-header {
            background-color: #ebebef;

            >tr {
              .action-left {
                width: 215px;
                min-width: 215px;
                padding: 0.85em 0em 1em 6.5em;
                box-sizing: border-box;
              }

              >th {
                padding: 0.85em 0em 1em 1.35em;
                color: #333333;
                font-size: 0.8em;
                font-weight: 600;
                width: 22em;
                text-align: start;

                >img {
                  width: 1.1em;
                  height: 1.1em;
                  margin-left: 0.9em;
                }
              }
            }
          }

          tbody {
            background-color: white;
            border-right: 0.2em solid transparent;
            border-left: 0.2em solid transparent;
            overflow-x: scroll;

            .table-row {
              padding-left: 1em;
              cursor: pointer;

              &[data-connector-status="true"] {
                &>td {
                  opacity: 0.3;
                }

                &>.restore-td {
                  opacity: 1;
                }

                // pointer-events: none;
              }
            }

            >tr {
              margin-left: 1em;

              td:last-child {
                :first-child {
                  justify-content: space-evenly;
                }
              }

              >td {
                padding: 0.5em 1.2em 0.5em 1.25em;
                font-size: 0.85em;
                font-weight: 500;
                border-bottom: 1px solid #dddddd;

                >img {
                  height: 1.4em;
                  margin-right: 1em;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .additional {
    .inputs-div {
      .input-and-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;

        .label {
          margin-bottom: 0.6em;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .details {
    flex-direction: column;
    row-gap: 20px;
  }

  .additional {
    display: grid;
    grid-template-columns: 1fr !important;
  }
}

@media only screen and (max-width: 500px) {}