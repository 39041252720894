@import "../../../styles//colors.scss";

.notification-type {
  margin-top: 1.2rem;
  display: flex;
  justify-content: space-between; 

  .notification-info-div {
    margin-left: 1.47rem;
  }

  .left-div {
    display: flex;
  }

  .notification-text {
    color: white;
    font-size: 0.9em;
  }
  .notification-connector-container {
    margin-left: 2.5em;
    >p{
      margin-bottom: 1em;
    }
  }

  .color-text {
    color: $secondary;
    font-size: 0.9em;
    font-weight: 600;
  }

  .date-text {
    margin-top: 0.2em;
    font-size: 0.7em;
    color: #a5acb8;
  }

  .right-div {
  }
}
