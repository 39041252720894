@import "../../styles/colors.scss";

.login-container {
  min-height: 100vh;
  width: 100%;
  background-color: $dark_background;
  display: flex;

  .left-side {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .logo-icon {
    width: 23rem;
  }

  .logo-div {
    min-width: 22rem;
    display: flex;
    align-items: center;
    margin-top: 2rem;

    .logo-text {
      color: white;
      font-weight: 800;
      font-size: 3rem;
      margin-left: 2rem;
    }
  }

  .right-side {
    display: flex;
    flex: 1.25;
    justify-content: flex-start;
    align-items: center;
  }

  .bg-image {
    height: 70%;
    min-width: 46rem;
    background-image: url("../../assets/images/login-image.svg");
    /* The image used */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;

    >h1 {
      color: white;
      text-align: center;
      margin-top: 4rem;
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 3rem;
      letter-spacing: 2.2px;
    }

    .text-input {
      width: 100%;
      height: 4.2rem;
      border-radius: 18px;
      overflow: hidden;
      margin-top: 1.5rem;

      >input {
        padding: 0 2rem;
        font-size: 0.9rem;
        font-weight: 600;
        height: 100%;
        color: #6e7191;
        border-style: none;

        &::placeholder {
          color: #6e7191;
        }
      }
    }

    .button-div {
      width: 55%;
      height: 4.2rem;
      border-radius: 18px;
      margin-top: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      >p {
        font-size: 1rem;
        margin-right: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 1230px) {
  .login-container {
    .left-side {
      display: none;
    }
    .right-side{
      justify-content: center;
    }

  }
}
@media only screen and (max-width: 720px) {
  .login-container {
    .right-side{
      .bg-image{
        min-width: 100%;
        >h1 {
          margin-left: 50px;
          margin-right: 50px;
        }
      }
    }

  }
}